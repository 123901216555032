import React from 'react'
import { Wrapper, Layout } from 'compositions/Application'
import Routes from '@kitchen/routes'
import { theme } from '@kitchen/theme'
import NavBar from '@kitchen/compositions/NavBar'
import NavDrawer from '@kitchen/compositions/NavDrawer'
import { ContextProvider } from './Context'

export const Application: React.FC = () =>
  <Wrapper theme={theme} apolloClient="kitchen">
    <ContextProvider>
      <Layout navBar={NavBar} navDrawer={NavDrawer}>
        <Routes />
      </Layout>
    </ContextProvider>
  </Wrapper>

export default Application
