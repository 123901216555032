import { useMemo } from 'react'
import { vendorFulfillmentReportsQuery } from '@kitchen/graphql/queries'
import { useKitchenQuery } from './useKitchenQuery'
import type { Scalars, VendorFulfillmentReport } from '@kitchen/graphql/schema/graphql'
import { DateTime } from 'luxon'

type VendorFulfillmentQueryArgs = {
  vendorLocationId: string
  schoolLocationId?: string
  mode?: "meals" | "servings" | "legacy"
  startDate?: Scalars['ISO8601DateTime']['input']
  endDate?: Scalars['ISO8601DateTime']['input']
}

type VendorFulfillmentReportArgs = VendorFulfillmentQueryArgs & {
  pollInterval?: number
}

export const useVendorFulfillmentReports = ({ vendorLocationId, schoolLocationId, startDate, endDate, mode, pollInterval }: VendorFulfillmentReportArgs ) => {
  const { data, ...rest } = useKitchenQuery<{ vendorFulfillmentReports: VendorFulfillmentReport[] }, VendorFulfillmentQueryArgs>(
    vendorFulfillmentReportsQuery,
    {
      variables: {
        vendorLocationId,
        schoolLocationId,
        startDate,
        endDate,
        mode,
      },
      pollInterval,
      // notifyOnNetworkStatusChange: true
    }
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadedAt = useMemo(() => DateTime.now(), [data]) // we only want to regenerate this when the data is fetched/changed

  return {
    ...rest,
    loadedAt,
    data: {
      vendorFulfillmentReports: [],
      ...data
    }
  }
}

export default useVendorFulfillmentReports
