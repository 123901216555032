import React from 'react'
import { VendorFulfillmentReport } from '@kitchen/graphql/schema/graphql'
import { Box } from 'components'
import { OrderCard } from '@kitchen/components/OrderCard'

export const ReportGrid: React.FC<VendorFulfillmentReport> = ({ rows }) => {

  return <Box display="flex" flexWrap="wrap" justifyContent="center" gap={4} pb={6}>
    { rows.map((row) => <OrderCard key={row.variant.id} variant={row.variant} total={row.total} />)}
  </Box>
}

export default ReportGrid
