import React from 'react'
import {
  Box,
  Button,
  Text,
  CheckboxFilter,
  TextFieldFilter,
} from 'components'
import { ContentDrawerPortal } from 'compositions/ContentDrawer'
import { FilterProps } from '@kitchen/hooks/useFilteredVendorProducts'

export type FiltersDrawerProps = Pick<FilterProps, "filters" | "filterDefinitions" | "setFilters" | "setAndApplyFilters" | "applyFilters" | "clearFilters" | "applicable" | "clearable">

export const FiltersDrawer: React.FC<FiltersDrawerProps> = ({ filters, filterDefinitions, /*applicable,*/ clearable, setFilters, applyFilters, setAndApplyFilters, clearFilters }) => {
  return <ContentDrawerPortal overflow="hidden" drawerType="filters">
    <Box height="100%" display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column" overflow="auto" p={2}>
        <Box pb={4}>
          <Text variant="h5" fontWeight="medium">Filters</Text>
        </Box>

        <CheckboxFilter filterName="productLocationIds" filterDefinitions={filterDefinitions} filters={filters} setFilters={setFilters} row />
        {/* <CheckboxFilter filterName="productMenuIds" filterDefinitions={filterDefinitions} filters={filters} setFilters={setFilters} row /> */}
        <CheckboxFilter filterName="productTemperatureTagIds" filterDefinitions={filterDefinitions} filters={filters} setFilters={setFilters} row />
        <CheckboxFilter filterName="productDietaryTagIds" filterDefinitions={filterDefinitions} filters={filters} setFilters={setFilters} row />
        <CheckboxFilter filterName="productType" filterDefinitions={filterDefinitions} filters={filters} setFilters={setFilters} row />
        <TextFieldFilter filterName="productName" filterDefinitions={filterDefinitions} filters={filters} setFilters={setFilters} applyFilters={applyFilters} setAndApplyFilters={setAndApplyFilters} />
      </Box>

      <Box p={2}>
        <Button onClick={() => clearFilters()} color="inherit" variant="text" disabled={!clearable}>Clear</Button>
        <Button variant="contained" onClick={applyFilters}>Apply</Button>
      </Box>
    </Box>
  </ContentDrawerPortal>
}

export default FiltersDrawer
