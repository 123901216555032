import { useEffect, useMemo } from 'react'
import type { Menu, Scalars, SchoolLocation, Tag, VendorLocation } from '@kitchen/graphql/schema/graphql'
import { useFilters, Filter, FilterProps as BaseFilterProps } from 'hooks/useFilters'
import { useVendorMenus } from './useVendorMenus'
import { DateTime } from 'luxon'

export interface FilterState {
  startDate: Scalars['ISO8601Date']['input']
  endDate: Scalars['ISO8601Date']['input']
  timeZone: string
  schoolLocationIds: string[]
  productTemperatureTagIds: string[]
  productDietaryTagIds: string[]
  productType: string[]
  hideDates: ('weekends' | 'empty')[]
  visibleMenuProducts: boolean
}

export type FilterProps = Pick<BaseFilterProps<FilterState, Menu>, "filters" | "appliedFilters" | "filterDefinitions" | "setFilters" | "applyFilters" | "setAndApplyFilters" | "clearFilters" | "applicable" | "clearable"> & Pick<ReturnType<typeof useVendorMenus>, "loading" | "loadedAt"> & {
  availableSchoolLocations: SchoolLocation[]
  availableProductTags: Tag[]
}

const timeZoneFilter: Filter<FilterState, Menu, 'timeZone'> = {
  name: 'timeZone',
  label: 'Time Zone',
}

const startDateFilter: Filter<FilterState, Menu, 'startDate'> = {
  name: 'startDate',
  label: 'Start Date',
}

const endDateFilter: Filter<FilterState, Menu, 'endDate'> = {
  name: 'endDate',
  label: 'End Date',
}

const schoolLocationFilter: Filter<FilterState, Menu, 'schoolLocationIds'> = {
  name: 'schoolLocationIds',
  label: 'Locations',
  match: (menu, { schoolLocationIds }) => (schoolLocationIds.length === 0 || schoolLocationIds.indexOf(menu.location.id) > -1),
}

const visibleMenuProductsFilter: Filter<FilterState, Menu, 'visibleMenuProducts'> = {
  name: 'visibleMenuProducts',
  label: 'Visible',
  process: (menu, { visibleMenuProducts }) => {
    const processedMenu = { ...menu, menuProducts: [] }

    menu.menuProducts.forEach((menuProduct) => {
      if (!visibleMenuProducts || menuProduct.visible) {
        processedMenu.menuProducts.push(menuProduct)
      }
    })

    return processedMenu
  },
}

const productTemperatureTagFilter: Filter<FilterState, Menu, 'productTemperatureTagIds'> = {
  name: 'productTemperatureTagIds',
  label: 'Temperature',
  process: (menu, { productTemperatureTagIds }) => {
    const stateTagIds = productTemperatureTagIds
    const processedMenu = { ...menu, menuProducts: [] }

    menu.menuProducts.forEach((menuProduct) => {
      const menuProductTagIds = menuProduct.product.tags.map((tag) => tag.id)
      let match = true

      if (stateTagIds.length > 0 && !stateTagIds.some(tagId => menuProductTagIds.includes(tagId))) {
        match = false
      }

      if (match) {
        processedMenu.menuProducts.push(menuProduct)
      }
    })

    return processedMenu
  },
}

const productDietaryTagFilter: Filter<FilterState, Menu, 'productDietaryTagIds'> = {
  name: 'productDietaryTagIds',
  label: 'Dietary',
  process: (menu, { productDietaryTagIds }) => {
    const stateTagIds = productDietaryTagIds
    const processedMenu = { ...menu, menuProducts: [] }

    menu.menuProducts.forEach((menuProduct) => {
      const menuProductTagIds = menuProduct.product.tags.map((tag) => tag.id)
      let match = true

      if (stateTagIds.length > 0 && !stateTagIds.every(tagId => menuProductTagIds.includes(tagId))) {
        match = false
      }

      if (match) {
        processedMenu.menuProducts.push(menuProduct)
      }
    })

    return processedMenu
  },
}

export const PRODUCT_TYPES = {
  bundle: "Bundle",
  beverage: "Beverage",
  main_dish: "Main Dish",
  side_dish: "Side Dish",
  condiment: "Condiment",
}

const productTypeFilter: Filter<FilterState, Menu, 'productType'> = {
  name: 'productType',
  label: 'Product Type',
  process: (menu, { productType }) => {
    const stateTypes = productType
    const processedMenu = { ...menu, menuProducts: [] }

    menu.menuProducts.forEach((menuProduct) => {
      let match = true

      if (stateTypes.length > 0) {
        if (!stateTypes.includes(menuProduct.product.productType)) match = false
      }

      if (match) {
        processedMenu.menuProducts.push(menuProduct)
      }
    })

    return processedMenu
  },
}

const hideDatesFilter: Filter<FilterState, Menu, 'hideDates'> = {
  name: 'hideDates',
  label: 'Hide Dates',
  options: [
    { key: 'Hide Weekends', value: 'weekends'},
    { key: 'Hide Empty Dates', value: 'empty'},
  ]
}

export const useFilteredVendorMenus = (vendorLocation: VendorLocation, initialState?: Partial<FilterState>) => {
  const filters = useFilters<FilterState, Menu>(
    [
      timeZoneFilter,
      startDateFilter,
      endDateFilter,
      schoolLocationFilter,
      productTemperatureTagFilter,
      productDietaryTagFilter,
      productTypeFilter,
      hideDatesFilter,
      visibleMenuProductsFilter,
    ],
    {
      timeZone: vendorLocation?.timeZone,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      startDate: DateTime.fromISO(initialState?.startDate || DateTime.now().toISO()).setZone(vendorLocation?.timeZone).startOf('week', { useLocaleWeeks: true }),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      endDate: DateTime.fromISO(initialState?.endDate || initialState?.startDate || DateTime.now().toISO()).setZone(vendorLocation?.timeZone).endOf('week', { useLocaleWeeks: true }),
      schoolLocationIds: [...(initialState?.schoolLocationIds || [])],
      productTemperatureTagIds: [...(initialState?.productTemperatureTagIds || [])],
      productDietaryTagIds: [...(initialState?.productDietaryTagIds || [])],
      productType: [],
      hideDates: [...(initialState?.hideDates || [])],
      visibleMenuProducts: true,
    },
    [
      'startDate',
      'endDate'
    ]
  )

  const { filterData, appliedFilters, setFilterOptions } = filters

  const { data, ...rest } = useVendorMenus({
    organizationId: vendorLocation?.organization?.id,
    locationId: vendorLocation?.id,
    startDate: appliedFilters.startDate.toISO(),
    endDate: appliedFilters.endDate.toISO(),
    pollInterval: 600000,
  })

  const availableSchoolLocations = useMemo(
    () =>
      data?.vendorMenus.map((menu) => menu.location)
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort((a, b) => a.displayName.localeCompare(b.displayName)) || [],
    [data?.vendorMenus]
  )

  const availableProductTags = useMemo(
    () =>
      data?.vendorMenus.flatMap((menu) => menu.menuProducts.flatMap((menuProduct) => menuProduct.product.tags))
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort((a, b) => a.name.localeCompare(b.name)) || [],
    [data?.vendorMenus]
  )

  const availableProductTypes = useMemo(
    () =>
    data?.vendorMenus?.flatMap((menu) => menu.menuProducts.map((menuProduct) => menuProduct.product.productType))
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort((a, b) => a.localeCompare(b)) || [],
    [data?.vendorMenus]
  )

  useEffect(() => {
    setFilterOptions('schoolLocationIds', availableSchoolLocations.map((location) => ({ key: location.displayName, value: location.id })))
    setFilterOptions('productTemperatureTagIds', availableProductTags.filter((tag) => tag.type === "temperature").map((tag) => ({ key: tag.name, value: tag.id })))
    setFilterOptions('productDietaryTagIds', availableProductTags.filter((tag) => tag.type === "dietary").map((tag) => ({ key: tag.name, value: tag.id })))
    setFilterOptions('productType', availableProductTypes.map((type) => ({ key: PRODUCT_TYPES[type], value: type })))
  }, [ setFilterOptions, availableSchoolLocations, availableProductTags, availableProductTypes ])

  const filteredMenus = useMemo(() => filterData(data.vendorMenus), [filterData, data.vendorMenus])

  return {
    data: {
      ...data,
      filteredMenus,
    },
    filters: {
      ...filters,
      availableSchoolLocations,
      availableProductTags,
      availableProductTypes,
    },
    ...rest
  }
}

export default useFilteredVendorMenus
