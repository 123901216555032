import React from 'react'
import { FilterProps } from '@kitchen/hooks/useFilteredVendorFulfillmentReports'
import { FiltersDrawer } from './FiltersDrawer'
import { FiltersHeader } from './FiltersHeader'

export const ReportFilters: React.FC<FilterProps> = ({
  loading,
  loadedAt,
  availableSchoolLocations,
  availableProductTags,
  appliedFilters,
  filters,
  filterDefinitions,
  applicable,
  clearable,
  setFilters,
  applyFilters,
  setAndApplyFilters,
  clearFilters,
}) => {
  return <>
    <FiltersDrawer
      filters={filters}
      filterDefinitions={filterDefinitions}
      applicable={applicable}
      clearable={clearable}
      setFilters={setFilters}
      applyFilters={applyFilters}
      setAndApplyFilters={setAndApplyFilters}
      clearFilters={clearFilters}
    />

    <FiltersHeader
      loading={loading}
      loadedAt={loadedAt}
      filters={appliedFilters}
      availableSchoolLocations={availableSchoolLocations}
      availableProductTags={availableProductTags}
      setAndApplyFilters={setAndApplyFilters}
    />
  </>
}

export default ReportFilters
