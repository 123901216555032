import { gql } from '@kitchen/graphql/schema/gql'

export const kitchenDocumentsQuery = gql(`
  query kitchenDocumentsQuery {
    kitchenDocuments {
      documents {
        ...documentFragment
      }
      documentTypeOptions {
        label
        value
      }
    }
  }
`)

export default kitchenDocumentsQuery
