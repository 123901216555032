import { useMemo } from 'react'
import { kitchenTodosQuery } from '@kitchen/graphql/queries'
import { useKitchenQuery } from './useKitchenQuery'
import type { TodosPayload, VendorLocation } from '@kitchen/graphql/schema/graphql'
import { DateTime } from 'luxon'
import {
  Documents as DocumentsIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  Vendor as VendorIcon,
} from 'icons'
import {
  UploadKitchenDocumentTodo,
  ChangePasswordTodo,
  KitchenSettingsTodo,
} from '@kitchen/components/Todos'
import { TodoWrapper } from 'components/Todos'
import useLazyKitchenQuery, { LazyQueryResultTuple } from './useLazyKitchenQuery'
import { useMe } from 'hooks'
import { ApolloQueryResult } from '@apollo/client'

type KitchenTodosQueryArgs = {
  locationId: string
}

type KitchenTodosArgs = {
  location: VendorLocation
  pollInterval?: number
}

const COMPONENT_MAP = {
  "UploadDocument": UploadKitchenDocumentTodo,
  "ChangePassword": ChangePasswordTodo,
  "LocationSettings": KitchenSettingsTodo,
}

export const ICON_MAP = {
  "UploadDocument": DocumentsIcon,
  "ChangePassword": LockIcon,
  "LocationSettings": VendorIcon,
}

export const useKitchenTodos = ({ location, pollInterval }: KitchenTodosArgs) => {
  const { hasLevel } = useMe()
  const { data, refetch, ...rest } = useKitchenQuery<{ todos: TodosPayload, kitchenTodos: TodosPayload }, KitchenTodosQueryArgs>(
    kitchenTodosQuery,
    {
      variables: { locationId: location?.id },
      pollInterval,
      // notifyOnNetworkStatusChange: true
    }
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadedAt = useMemo(() => DateTime.now(), [data]) // we only want to regenerate this when the data is fetched/changed

  const refetchTodos: (variables?: Partial<KitchenTodosQueryArgs>) => Promise<ApolloQueryResult<{ todos: TodosPayload, kitchenTodos: TodosPayload }>> = async (variables) => {
    const response = await refetch(variables)

    const todos = {
      ...response.data?.kitchenTodos,
      rows: (response.data?.kitchenTodos?.rows || []).filter((todo) => {
        if (!todo.definition.role) return true
        if (hasLevel(todo.definition.level, location)) return true
        return false
      }).map((todo) => ({
        ...todo,
        component: COMPONENT_MAP[todo?.definition?.type] || TodoWrapper,
        icon: ICON_MAP[todo?.definition?.type] || SettingsIcon,
      }))
    }

    return {
      ...response,
      data: {
        ...data,
        todos,
        kitchenTodos: todos,
      }
    }
  }

  const todos = {
    ...data?.kitchenTodos,
    rows: (data?.kitchenTodos?.rows || []).filter((todo) => {
      if (!todo.definition.role) return true
      if (hasLevel(todo.definition.level, location)) return true
      return false
    }).map((todo) => ({
      ...todo,
      component: COMPONENT_MAP[todo?.definition?.type] || TodoWrapper,
      icon: ICON_MAP[todo?.definition?.type] || SettingsIcon,
    }))
  }

  return {
    ...rest,
    loadedAt,
    refetch: refetchTodos,
    data: {
      ...data,
      todos,
      kitchenTodos: todos,
    }
  }
}

export const useLazyKitchenTodos = ({ location, pollInterval }: KitchenTodosArgs): LazyQueryResultTuple<{ todos: TodosPayload, kitchenTodos: TodosPayload }, KitchenTodosQueryArgs> => {
  const { hasLevel } = useMe()
  const [ loadTodos, { data, refetch, ...rest }] = useLazyKitchenQuery<{ todos: TodosPayload, kitchenTodos: TodosPayload }, KitchenTodosQueryArgs>(
    kitchenTodosQuery,
    {
      variables: { locationId: location?.id },
      pollInterval,
      // notifyOnNetworkStatusChange: true
    }
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadedAt = useMemo(() => DateTime.now(), [data]) // we only want to regenerate this when the data is fetched/changed

  const refetchTodos: (variables?: Partial<KitchenTodosQueryArgs>) => Promise<ApolloQueryResult<{ todos: TodosPayload, kitchenTodos: TodosPayload }>> = async (variables) => {
    const response = await refetch(variables)

    const todos = {
      ...response.data?.kitchenTodos,
      rows: (response.data?.kitchenTodos?.rows || []).filter((todo) => {
        if (!todo.definition.role) return true
        if (hasLevel(todo.definition.level, location)) return true
        return false
      }).map((todo) => ({
        ...todo,
        component: COMPONENT_MAP[todo?.definition?.type] || TodoWrapper,
        icon: ICON_MAP[todo?.definition?.type] || SettingsIcon,
      }))
    }

    return {
      ...response,
      data: {
        ...data,
        todos,
        kitchenTodos: todos,
      }
    }
  }

  const todos = {
    ...data?.kitchenTodos,
    rows: (data?.kitchenTodos?.rows || []).filter((todo) => {
      if (!todo.definition.role) return true
      if (hasLevel(todo.definition.level, location)) return true
      return false
    }).map((todo) => ({
      ...todo,
      component: COMPONENT_MAP[todo?.definition?.type] || TodoWrapper,
      icon: ICON_MAP[todo?.definition?.type] || SettingsIcon,
    }))
  }

  return [
    loadTodos,
    {
      ...rest,
      loadedAt,
      refetch: refetchTodos,
      data: {
        ...data,
        todos,
        kitchenTodos: todos,
      }
    },
  ]
}

export default useKitchenTodos
