import React from 'react'
import { ProductsTable } from './ProductsTable'
import { ProductsGrid } from './ProductsGrid'
import { Product, QuerySortInput } from '@kitchen/graphql/schema/graphql'
import { FilterPagination, ApplyPagination, ApplySorting } from 'hooks/useFilters'

export const Products: React.FC<{
  products: Product[],
  pagination: FilterPagination,
  applyPagination: ApplyPagination,
  sorting: QuerySortInput[],
  applySorting: ApplySorting,
  display?: 'table' | 'grid' | null,
}> = ({
  products,
  pagination,
  applyPagination,
  sorting,
  applySorting,
  display='table'
}) => {

  return <>
    { display === 'table' && <ProductsTable products={products} pagination={pagination} applyPagination={applyPagination} sorting={sorting} applySorting={applySorting} /> }
    { display === 'grid' && <ProductsGrid products={products} /> }
  </>
}

export default Products
