import React, { useCallback, useMemo } from 'react'
import {
  Box,
  Button,
  Text,
  DateRangePicker,
  DateRangeButtonFilter,
  CheckboxFilter,
  TextFieldFilter,
  ToggleButton,
  ToggleButtonGroup,
} from 'components'
import { ContentDrawerPortal } from 'compositions/ContentDrawer'
import { DateTime } from 'luxon'
import { DateRange } from '@mui/x-date-pickers-pro'
import { FilterProps } from '@kitchen/hooks/useFilteredVendorFulfillmentReports'

export type FiltersDrawerProps = Pick<FilterProps, "filters" | "filterDefinitions" | "setFilters" | "setAndApplyFilters" | "applyFilters" | "clearFilters" | "applicable" | "clearable">

export const FiltersDrawer: React.FC<FiltersDrawerProps> = ({ filters, filterDefinitions, /*applicable,*/ clearable, setFilters, applyFilters, setAndApplyFilters, clearFilters }) => {
  const dateRange: DateRange<DateTime> = useMemo(() => [filters.startDate, filters.endDate], [filters.startDate, filters.endDate])
  const onDateRangeChange = useCallback((newDates: DateRange<DateTime>) => setAndApplyFilters({ startDate: (newDates[0] || filters.startDate), endDate: (newDates[1] || filters.endDate) }), [filters.startDate, filters.endDate, setAndApplyFilters])
  const setMode = useCallback((_e: React.MouseEvent<HTMLElement, MouseEvent>, mode: "meals" | "servings") => setAndApplyFilters({ mode }), [setAndApplyFilters])

  return <ContentDrawerPortal overflow="hidden" drawerType="filters">
    <Box height="100%" display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column" overflow="auto" p={2}>
        <Box pb={4}>
          <Text variant="h5" fontWeight="medium">Filters</Text>
        </Box>

        <Box pb={4}>
          <ToggleButtonGroup
            sx={{ width: "100%" }}
            value={filters.mode}
            exclusive
            onChange={setMode}
            color="primary"
          >
            <ToggleButton value="meals" size="small" title="Meals" sx={{ width: "50%", '&.Mui-selected': { color: 'white', backgroundColor: 'plain.main' }, '&.Mui-selected:hover': { color: 'white', backgroundColor: 'plain.main' } }}>
              <Text textTransform="none" fontWeight="bold">Meals</Text>
            </ToggleButton>
            <ToggleButton value="servings" size="small" title="Servings" sx={{ width: "50%", '&.Mui-selected': { color: 'white', backgroundColor: 'plain.main' }, '&.Mui-selected:hover': { color: 'white', backgroundColor: 'plain.main' } }}>
              <Text textTransform="none" fontWeight="bold">Servings</Text>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Box width="100%" pb={4}>
          <Text variant="subtitle1" fontWeight="medium" pb={2}>Date range</Text>

          <DateRangePicker
            closeOnSelect={true}
            value={dateRange}
            onAccept={onDateRangeChange}
          />

          <Box pt={2} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignContent: 'space-around' }}>
            <Box>
              <DateRangeButtonFilter
                startDate={filters.startDate}
                endDate={filters.endDate}
                newStartDate={DateTime.now().setZone(filters.timeZone).startOf('day')}
                newEndDate={DateTime.now().setZone(filters.timeZone).endOf('day')}
                setFilters={setAndApplyFilters}
              >Today</DateRangeButtonFilter>
             </Box>


             <Box>
              <DateRangeButtonFilter
                startDate={filters.startDate}
                endDate={filters.endDate}
                newStartDate={DateTime.now().setZone(filters.timeZone).startOf('day').plus({ days: 1 })}
                newEndDate={DateTime.now().setZone(filters.timeZone).endOf('day').plus({ days: 1 })}
                setFilters={setAndApplyFilters}
              >Tomorrow</DateRangeButtonFilter>
            </Box>
          </Box>

          <Box pt={2} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignContent: 'space-around' }}>
            <Box>
              <DateRangeButtonFilter
                startDate={filters.startDate}
                endDate={filters.endDate}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newStartDate={DateTime.now().setZone(filters.timeZone).startOf('week', { useLocaleWeeks: true })}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newEndDate={DateTime.now().setZone(filters.timeZone).endOf('week', { useLocaleWeeks: true })}
                setFilters={setAndApplyFilters}
              >This Week</DateRangeButtonFilter>
            </Box>

            <Box>
              <DateRangeButtonFilter
                startDate={filters.startDate}
                endDate={filters.endDate}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newStartDate={DateTime.now().setZone(filters.timeZone).startOf('week', { useLocaleWeeks: true }).plus({ weeks: 1 })}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newEndDate={DateTime.now().setZone(filters.timeZone).endOf('week', { useLocaleWeeks: true }).plus({ weeks: 1 })}
                setFilters={setAndApplyFilters}
              >Next Week</DateRangeButtonFilter>
            </Box>
          </Box>
        </Box>

        <CheckboxFilter filterName="schoolLocationIds" filterDefinitions={filterDefinitions} filters={filters} setFilters={setFilters} />
        <CheckboxFilter filterName="productTemperatureTagIds" filterDefinitions={filterDefinitions} filters={filters} setFilters={setFilters} row />
        <CheckboxFilter filterName="productDietaryTagIds" filterDefinitions={filterDefinitions} filters={filters} setFilters={setFilters} row />
        <CheckboxFilter filterName="productType" filterDefinitions={filterDefinitions} filters={filters} setFilters={setFilters} row />
        <TextFieldFilter filterName="productName" filterDefinitions={filterDefinitions} filters={filters} setFilters={setFilters} applyFilters={applyFilters} setAndApplyFilters={setAndApplyFilters} />
      </Box>

      <Box p={2}>
        <Button onClick={() => clearFilters()} color="inherit" variant="text" disabled={!clearable}>Clear</Button>
        <Button variant="contained" onClick={applyFilters}>Apply</Button>
      </Box>
    </Box>
  </ContentDrawerPortal>
}

export default FiltersDrawer
