import { useEffect } from 'react'
import { useCurrentVendorLocation } from '@kitchen/hooks/useCurrentVendorLocation'
import { updateKitchenSettingsMutation } from '@kitchen/graphql/mutations'
import { MutationTuple, useKitchenMutation } from './useKitchenMutation'
import type { KitchenSettingsInput, UpdateKitchenSettingsPayload } from '@kitchen/graphql/schema/graphql'

export type UpdateKitchenSettingsMutationData = { updateKitchenSettings: UpdateKitchenSettingsPayload }

export const useUpdateKitchenSettings: (variables?: KitchenSettingsInput) => MutationTuple<UpdateKitchenSettingsMutationData, KitchenSettingsInput> = (variables)  => {
  const { setCurrentVendorLocation } = useCurrentVendorLocation()

  const [ updateKitchenSettingsFn, { data, ...result } ] = useKitchenMutation<UpdateKitchenSettingsMutationData, KitchenSettingsInput>(
    updateKitchenSettingsMutation,
    {
      variables,
      // notifyOnNetworkStatusChange: true
    }
  )

  useEffect(() => {
    if (data?.updateKitchenSettings?.settings?.kitchen) setCurrentVendorLocation(data.updateKitchenSettings.settings.kitchen)
  }, [ data, setCurrentVendorLocation ])

  return [ updateKitchenSettingsFn, { ...result, data } ]
}

export default useUpdateKitchenSettings
