import React from 'react'
import { Alert, Box, Form, FormTextField, FormTelephoneField, FormSelect, FormSubmitButton, Text } from 'components'
import { useUpdateKitchenSettingsForm, useKitchenSettings } from '@kitchen/hooks'
import { VendorLocation } from '@kitchen/graphql/schema/graphql'
import { OnSuccessFn } from 'hooks/useMutationForm'
import { KitchenSettingsFormInput } from '@kitchen/hooks/useUpdateKitchenSettingsForm'
import { UpdateKitchenSettingsMutationData } from '@kitchen/hooks/useUpdateKitchenSettings'

export const KitchenSettingsForm: React.FC<{ kitchen: VendorLocation, onSuccess?: OnSuccessFn<UpdateKitchenSettingsMutationData, KitchenSettingsFormInput> }> = ({ kitchen, onSuccess }) => {
  const { loadedAt, data: { kitchenSettings: settings } } = useKitchenSettings({ locationId: kitchen?.id })
  const [ mutationForm, { errors, loading, submitted } ] = useUpdateKitchenSettingsForm({ ...kitchen, settings }, { onSuccess })

  return (
    <Form form={mutationForm} key={loadedAt.toString()}>
      <Box>
        <Text variant="h6" mb={2}>Basic info</Text>

        <FormTextField
          id="vendorName"
          name="vendorName"
          label="Vendor"
          disabled
        />

        <FormTextField
          id="kitchenName"
          name="kitchenName"
          label="Kitchen"
        />
      </Box>

      <Box>
        <Text variant="h6" mb={2}>Contact info</Text>

        <FormTextField
          id="address.line1"
          name="address.line1"
          label="Street address"
        />

        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <FormTextField
            id="address.city"
            name="address.city"
            label="City"
            sx={{ mr: 1 }}
          />

          <FormSelect
            id="address.stateProvince"
            name="address.stateProvince"
            label="State"
            options={settings.kitchen?.address?.stateProvinceOptions}
            sx={{ ml: 1, mr: 1 }}
          />

          <FormTextField
            id="address.postalCode"
            name="address.postalCode"
            label="Postal code"
            sx={{ ml: 1 }}
          />
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <FormTextField
            id="address.contactFirstName"
            name="address.contactFirstName"
            label="Contact person first name"
            sx={{ mr: 1 }}
          />

          <FormTextField
            id="address.contactLastName"
            name="address.contactLastName"
            label="Contact person last name"
            sx={{ ml: 1 }}
          />
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <FormTelephoneField
            id="address.phoneNumber"
            name="address.phoneNumber"
            label="Contact phone number"
            placeholder="(555) 555-1212"
            sx={{ mr: 1, flexGrow: 2 }}
          />

          <FormTextField
            id="address.phoneExtension"
            name="address.phoneExtension"
            label="Extension"
            sx={{ ml: 1, flexGrow: 1, width: "40%" }}
          />
        </Box>
      </Box>

      {/* <Box>
        <Text variant="h6" mb={2}>Payments</Text>

        <FormTextField
          id="tipaltiId"
          name="tipaltiId"
          label="Tipalti ID"
          disabled={true}
          helperText="If you need to make a change to your Tipalti ID, please submit a support request"
        />

        <FormTextField
          id="invoicePrefix"
          name="invoicePrefix"
          label="Tipalti invoice prefix"
          disabled={true}
        />
      </Box> */}

      {/* <FormTextField
        id="paddingType"
        name="paddingType"
        label="Padding Type"
      />

      <FormTextField
        type="number"
        id="paddingValue"
        name="paddingValue"
        label="Padding Value"
      />

      <FormTextField
        type="number"
        id="serviceFees"
        name="serviceFees"
        label="Service Fees"
      /> */}

      { !submitted && <>{ errors.map((error) => <Alert key={`${error.code}:${error.message}`} severity="error" sx={{ width: '100%', mb: 2 }}>
        {error.message}
      </Alert>)}</> }

      <FormSubmitButton
        loading={loading}
      >
        Save settings
      </FormSubmitButton>
    </Form>
  )
}

export default KitchenSettingsForm
