import { useMemo } from 'react'
import type { Document } from '@kitchen/graphql/schema/graphql'
import { useFilters, Filter, FilterProps as BaseFilterProps } from 'hooks/useFilters'
import { useKitchenDocuments } from './useKitchenDocuments'

export interface FilterState {
  expiredDocuments: boolean
}

export type FilterProps = Pick<BaseFilterProps<FilterState, Document>, "filters" | "appliedFilters" | "filterDefinitions" | "setFilters" | "applyFilters" | "setAndApplyFilters" | "clearFilters" | "applicable" | "clearable"> & Pick<ReturnType<typeof useKitchenDocuments>, "loading" | "loadedAt">

const expiredDocumentsFilter: Filter<FilterState, Document, 'expiredDocuments'> = {
  name: 'expiredDocuments',
  label: 'Expired',
  match: (document, { expiredDocuments }) => {
    if (!expiredDocuments && document.expired) return false
    return true
  },
}

export const useFilteredKitchenDocuments = (initialState?: Partial<FilterState>) => {
  const filters = useFilters<FilterState, Document>(
    [
      expiredDocumentsFilter,
    ],
    {
      expiredDocuments: initialState?.expiredDocuments === undefined ? false : initialState.expiredDocuments,
    }
  )

  const { filterData } = filters

  const { data, ...rest } = useKitchenDocuments({
    pollInterval: 600000,
  })

  const filteredKitchenDocuments = useMemo(() => filterData(data.kitchenDocuments.documents), [filterData, data.kitchenDocuments.documents])

  return {
    data: {
      ...data,
      filteredKitchenDocuments,
    },
    filters: {
      ...filters,
    },
    ...rest
  }
}

export default useFilteredKitchenDocuments
