import { gql } from '@kitchen/graphql/schema/gql'

export const vendorVariantsQuery = gql(`
  query vendorVariantsQuery($organizationId: ID!) {
    vendorVariants(organizationId: $organizationId) {
      id
      product {
        ...VendorProductFields
      }
      location {
        id
        name
      }
    }
  }
`)

export default vendorVariantsQuery
