import { gql } from '@kitchen/graphql/schema/gql'

export const vendorMenusQuery = gql(`
  query vendorMenusQuery($organizationId: ID!, $locationId: ID, $startDate: ISO8601Date, $endDate: ISO8601Date) {
    vendorMenus(organizationId: $organizationId, locationId: $locationId, startDate: $startDate, endDate: $endDate) {
      id
      name
      description
      fullAvailabilityDates
      location {
        id
        name
        displayName
      }
      menuProducts(locationId: $locationId) {
        id
        fullAvailabilityDates
        visible
        section {
          id
          name
        }
        product {
          ...VendorProductFields
        }
        variant {
          id
          product {
            ...VendorProductFields
          }
        }
      }
    }
  }
`)

export default vendorMenusQuery
