import { useEffect, useMemo } from 'react'
import { DateTime } from 'luxon'
import type { VendorFulfillmentReport, Scalars, SchoolLocation, Tag, VendorLocation } from '@kitchen/graphql/schema/graphql'
import { useFilters, Filter, FilterProps as BaseFilterProps } from 'hooks/useFilters'
import { useVendorFulfillmentReports } from './useVendorFulfillmentReports'

export interface FilterState {
  display: "table" | "grid"
  mode: "meals" | "servings" | "legacy" | undefined
  timeZone: string
  startDate: Scalars['ISO8601Date']['input']
  endDate: Scalars['ISO8601Date']['input']
  schoolLocationIds: string[]
  productTemperatureTagIds: string[]
  productDietaryTagIds: string[]
  productName: string
  productType: string[]
}

export type FilterProps = Pick<BaseFilterProps<FilterState, VendorFulfillmentReport>, "filters" | "appliedFilters" | "filterDefinitions" | "setFilters" | "applyFilters" | "setAndApplyFilters" | "clearFilters" | "applicable" | "clearable"> & Pick<ReturnType<typeof useVendorFulfillmentReports>, "loading" | "loadedAt"> & {
  availableSchoolLocations: SchoolLocation[]
  availableProductTags: Tag[]
}

const displayFilter: Filter<FilterState, VendorFulfillmentReport, 'display'> = {
  name: 'display',
}

const modeFilter: Filter<FilterState, VendorFulfillmentReport, 'mode'> = {
  name: 'mode',
  label: 'Mode',
}

const timeZoneFilter: Filter<FilterState, VendorFulfillmentReport, 'timeZone'> = {
  name: 'timeZone',
  label: 'Time Zone',
}

const startDateFilter: Filter<FilterState, VendorFulfillmentReport, 'startDate'> = {
  name: 'startDate',
  label: 'Start Date',
}

const endDateFilter: Filter<FilterState, VendorFulfillmentReport, 'endDate'> = {
  name: 'endDate',
  label: 'End Date',
}

const schoolLocationFilter: Filter<FilterState, VendorFulfillmentReport, 'schoolLocationIds'> = {
  name: 'schoolLocationIds',
  label: 'Locations',
  match: (report, { schoolLocationIds }) => (schoolLocationIds.length === 0 || schoolLocationIds.indexOf(report.location.id) > -1),
}

const productNameFilter: Filter<FilterState, VendorFulfillmentReport, 'productName'> = {
  name: 'productName',
  label: 'Product Name',
  process: (report, { productName }) => {
    const processedReport = { ...report, rows: [], total: 0 }

    report.rows.forEach((row) => {
      if (productName && productName !== '' && !row.variant.displayName.match(new RegExp(`${productName}`, 'i'))) return

      processedReport.rows.push(row)
      processedReport.total += row.total
    })

    return processedReport
  },
}

const productTemperatureTagFilter: Filter<FilterState, VendorFulfillmentReport, 'productTemperatureTagIds'> = {
  name: 'productTemperatureTagIds',
  label: 'Temperature',
  process: (report, { productTemperatureTagIds }) => {
    const stateTagIds = productTemperatureTagIds
    const processedReport = { ...report, rows: [], total: 0 }

    report.rows.forEach((row) => {
      const rowTagIds = row.variant.product.tags.map((tag) => tag.id)
      let match = true

      if (stateTagIds.length > 0 && !stateTagIds.some(tagId => rowTagIds.includes(tagId))) {
        match = false
      }

      if (match) {
        processedReport.rows.push(row)
        processedReport.total += row.total
      }
    })

    return processedReport
  },
}

const productDietaryTagFilter: Filter<FilterState, VendorFulfillmentReport, 'productDietaryTagIds'> = {
  name: 'productDietaryTagIds',
  label: 'Dietary',
  process: (report, { productDietaryTagIds }) => {
    const stateTagIds = productDietaryTagIds
    const processedReport = { ...report, rows: [], total: 0 }

    report.rows.forEach((row) => {
      const rowTagIds = row.variant.product.tags.map((tag) => tag.id)
      let match = true

      if (stateTagIds.length > 0 && !stateTagIds.every(tagId => rowTagIds.includes(tagId))) {
        match = false
      }

      if (match) {
        processedReport.rows.push(row)
        processedReport.total += row.total
      }
    })

    return processedReport
  },
}

export const PRODUCT_TYPES = {
  bundle: "Bundle",
  beverage: "Beverage",
  main_dish: "Main Dish",
  side_dish: "Side Dish",
  condiment: "Condiment",
}

const productTypeFilter: Filter<FilterState, VendorFulfillmentReport, 'productType'> = {
  name: 'productType',
  label: 'Product Type',
  process: (report, { productType }) => {
    const stateTypes = productType
    const processedReport = { ...report, rows: [], total: 0 }

    report.rows.forEach((row) => {
      let match = true

      if (stateTypes.length > 0) {
        if (!stateTypes.includes(row.variant.product.productType)) match = false
      }

      if (match) {
        processedReport.rows.push(row)
        processedReport.total += row.total
      }
    })

    return processedReport
  },
}

export const useFilteredVendorFulfillmentReports = (vendorLocation: VendorLocation, initialState?: Partial<FilterState>) => {
  const filters = useFilters<FilterState, VendorFulfillmentReport>(
    [
      displayFilter,
      modeFilter,
      timeZoneFilter,
      startDateFilter,
      endDateFilter,
      schoolLocationFilter,
      productTemperatureTagFilter,
      productDietaryTagFilter,
      productNameFilter,
      productTypeFilter,
    ],
    {
      display: "grid",
      mode: "meals",
      timeZone: vendorLocation?.timeZone,
      startDate: DateTime.fromISO(initialState?.startDate || DateTime.now().toISO()).setZone(vendorLocation?.timeZone).startOf('day'),
      endDate: DateTime.fromISO(initialState?.endDate || initialState?.startDate || DateTime.now().toISO()).setZone(vendorLocation?.timeZone).endOf('day'),
      schoolLocationIds: [...(initialState?.schoolLocationIds || [])],
      productTemperatureTagIds: [...(initialState?.productTemperatureTagIds || [])],
      productDietaryTagIds: [...(initialState?.productDietaryTagIds || [])],
      productName: undefined,
      productType: [],
    },
    [
      'display',
      'mode',
      'startDate',
      'endDate',
    ]
  )

  const { filterData, appliedFilters, setFilterOptions } = filters

  const { data, ...rest } = useVendorFulfillmentReports({
    vendorLocationId: vendorLocation?.id,
    startDate: appliedFilters.startDate.toISO(),
    endDate: appliedFilters.endDate.toISO(),
    mode: appliedFilters.mode,
    pollInterval: 600000,
  })

  const availableSchoolLocations = useMemo(
    () =>
      data?.vendorFulfillmentReports.map((report) => report.location)
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort((a, b) => a.displayName.localeCompare(b.displayName)) || [],
    [ data?.vendorFulfillmentReports ]
  )

  const availableProductTags = useMemo(
    () =>
    data?.vendorFulfillmentReports?.flatMap((report) => report.rows.flatMap((reportRow) => reportRow.variant.product.tags))
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort((a, b) => a.name.localeCompare(b.name)) || [],
    [data?.vendorFulfillmentReports]
  )

  // TODO: We should replace availableProductTags + filtering in the useEffect below with a single loop here that groups and returns two arrays of tags by type (but not try to do it in one line via partition)
  //
  // const [availableTemperatureTags, availableDietaryTags] = useMemo(
  //   () =>
  //   partition(data?.vendorFulfillmentReports?.flatMap((report) => report.rows.flatMap((reportRow) => reportRow.variant.product.tags))
  //     .filter((v, i, a) => a.indexOf(v) === i)
  //     .sort((a, b) => a.name.localeCompare(b.name)) || [], (tag) => tag.type === "temperature"),
  //   [data?.vendorFulfillmentReports]
  // )

  const availableProductTypes = useMemo(
    () =>
    data?.vendorFulfillmentReports?.flatMap((report) => report.rows.map((reportRow) => reportRow.variant.product.productType))
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort((a, b) => a.localeCompare(b)) || [],
    [data?.vendorFulfillmentReports]
  )

  useEffect(() => {
    setFilterOptions('schoolLocationIds', availableSchoolLocations.map((location) => ({ key: location.displayName, value: location.id })))
    setFilterOptions('productTemperatureTagIds', availableProductTags.filter((tag) => tag.type === "temperature").map((tag) => ({ key: tag.name, value: tag.id })))
    setFilterOptions('productDietaryTagIds', availableProductTags.filter((tag) => tag.type === "dietary").map((tag) => ({ key: tag.name, value: tag.id })))
    setFilterOptions('productType', availableProductTypes.map((type) => ({ key: PRODUCT_TYPES[type], value: type })))
  }, [ setFilterOptions, availableSchoolLocations, availableProductTags, availableProductTypes ])

  const filteredVendorFulfillmentReports = useMemo(() => filterData(data.vendorFulfillmentReports), [filterData, data.vendorFulfillmentReports])

  return {
    data: {
      ...data,
      filteredVendorFulfillmentReports,
    },
    filters: {
      ...filters,
      availableSchoolLocations,
      availableProductTags,
      availableProductTypes,
    },
    ...rest
  }
}

export default useFilteredVendorFulfillmentReports
