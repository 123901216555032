import React from 'react'
import { FormattedNumber } from 'react-intl'
import { Box, Text, Card, CardContent, CardMedia, Stack, TagChip } from 'components'
import Markdown from 'react-markdown'
import { Variant } from '@kitchen/graphql/schema/graphql'

export interface OrderCardProps {
  variant: Variant
  total: number 
}

export const OrderCard: React.FC<OrderCardProps> = ({ variant, total }) => <Card sx={{ display: 'flex', flexDirection: 'column' }}>
  <CardMedia sx={{ height: '4rem' }} loading="lazy" image={(variant.product.images[0]?.uri || variant.product?.featuredImage)} />

  <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box display="flex" pb={1}>
        <Box pr={1} textAlign="center">
          <Text variant="h6" lineHeight={1.1}><FormattedNumber value={total} /></Text>
        </Box>
        <Box pr={1}>
          <Text>&times;</Text>
        </Box>
        <Box>
          <Text variant="h6" lineHeight={1.1}>{ variant.product?.name }</Text>
        </Box>
      </Box>

      <Box pb={2}>
        <Stack direction="row" flexWrap="wrap">
          { variant.product?.tags?.filter((tag) => tag.type === "temperature").map((tag) => <TagChip key={tag.id} tag={tag} />) }
          { variant.product?.tags?.filter((tag) => tag.type === "meal_type").map((tag) => <TagChip key={tag.id} tag={tag} />) }
        </Stack>
      </Box>

      { variant.product?.description && <Box pb={2}>
        <Markdown>{ variant.product.description }</Markdown>
      </Box> }

      <Box flexGrow={1} display="flex" alignItems="flex-end">
        <Stack direction="row" flexWrap="wrap">
          { variant.product?.tags?.filter((tag) => tag.type === "dietary").map((tag) => <TagChip key={tag.id} tag={tag} />) }
        </Stack>
      </Box>
    </Box>
  </CardContent>
</Card>

export default OrderCard
