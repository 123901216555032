import React from 'react'
import { TodoWrapper, TodoWrapperProps } from 'components/Todos'
import { UploadDocumentForm } from 'compositions/Documents/UploadDocumentForm'
import { useUploadKitchenDocumentForm } from '@kitchen/hooks/useUploadKitchenDocumentForm'
import { useKitchenDocuments } from '@kitchen/hooks'
import { DocumentsList } from 'compositions'
import { Box } from 'components'

export const UploadKitchenDocumentTodo: React.FC<TodoWrapperProps> = ({ todo, wizard }) => {
  const { data: { kitchenDocuments: { documentTypeOptions, documents } }, refetch: refetchDocuments } = useKitchenDocuments({ pollInterval: 600000 })
  const filteredDocuments = documents.filter((document) => document.documentType === todo.definition.definition.document_type)
  const onSuccess = () => {
    wizard.resolve(todo).then(() => refetchDocuments().then(() => wizard.navigateToNext()))
  }
  const form = useUploadKitchenDocumentForm({ onSuccess, initialType: todo.definition.definition.document_type })

  return <TodoWrapper todo={todo} wizard={wizard}>
  { form && <UploadDocumentForm form={form} documentTypeOptions={documentTypeOptions.filter((opt) => opt.value == todo.definition.definition.document_type)} /> }
  { filteredDocuments.length > 0 && <>
    <Box my={4} borderBottom="1px solid #ccc"></Box>
    <DocumentsList documents={filteredDocuments} documentTypeOptions={documentTypeOptions} />
  </> }
</TodoWrapper>
}

export default UploadKitchenDocumentTodo
