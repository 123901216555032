import { enqueueSnackbar } from 'notistack'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { UploadKitchenDocumentMutationData, useUploadKitchenDocument } from '@kitchen/hooks/useUploadKitchenDocument'
import { useMutationForm, MutationFormConfig, MutationFormTuple, CoerceFn, OnSubmitFn, OnSuccessFn } from 'hooks/useMutationForm'
import { UploadKitchenDocumentInput } from '@kitchen/graphql/schema/graphql'
import { DateTime } from 'luxon'

export type { OnSubmitFn, OnSuccessFn }

export const zodSchema = z.object({
  type: z.string({
    required_error: 'Type is required',
  }).nonempty(),
  file: z.object({}, { required_error: 'File is required'}),
  expiresAt: z.optional(z.date().refine((expDate) => DateTime.fromISO(expDate.toISOString()) > DateTime.now(), { message: "Expiration date must be in the future if provided"})),
})

export const validationSchema = toFormikValidationSchema(zodSchema)

export const useUploadKitchenDocumentForm: (config?: MutationFormConfig<UploadKitchenDocumentMutationData, UploadKitchenDocumentInput> & { initialType?: string }) => MutationFormTuple<UploadKitchenDocumentMutationData, UploadKitchenDocumentInput> = ({ initialType, ...config }) => {
  const coerce: CoerceFn<UploadKitchenDocumentInput, UploadKitchenDocumentInput> = (values) => {
    const coerced: UploadKitchenDocumentInput = {
      type: values.type,
      file: values.file,
      expiresAt: values.expiresAt && DateTime.fromISO(values.expiresAt.toISOString()).toISODate(),
    }

    return coerced
  }

  const onSuccess: OnSuccessFn<UploadKitchenDocumentMutationData, UploadKitchenDocumentInput> = (data, helpers) => {
    enqueueSnackbar("Document uploaded", { variant: "success", autoHideDuration: 2000, preventDuplicate: true })
    if (config.onSuccess) config.onSuccess(data, helpers)
  }

  const [ mutationForm, mutationResult] = useMutationForm(useUploadKitchenDocument, {
    ...config,
    coerce,
    onSuccess,
    validationSchema,
    initialValues: {
      type: initialType || 'tax_form_w9',
      file: '',
      expiresAt: undefined,
    }
  })

  return [ mutationForm, mutationResult ]
}

export default useUploadKitchenDocumentForm