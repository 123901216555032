import React, { useCallback, useEffect, useState } from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import {
  Box,
  Chip,
  Text,
  ToggleButton,
  ToggleButtonGroup,
} from 'components'
import { Table as TableIcon, Gallery as GalleryIcon } from 'icons'
import { FilterState, FilterProps } from '@kitchen/hooks/useFilteredVendorProducts'
import { DateTime } from 'luxon'

export type FiltersHeaderProps = {
  filters: FilterState
} & Pick<FilterProps, "setAndApplyFilters" | "loading" | "loadedAt" | "availableTemperatureTags" | "availableDietaryTags" | "availableProductLocations" | "availableProductTypes">

export const FiltersHeader: React.FC<FiltersHeaderProps> = ({ loading, loadedAt, filters, availableTemperatureTags, availableDietaryTags, availableProductLocations, availableProductTypes, setAndApplyFilters }) => {
  const setDisplay = useCallback((_e: React.MouseEvent<HTMLElement, MouseEvent>, display: "grid" | "table") => setAndApplyFilters({ display }), [setAndApplyFilters])
  const [tick, setTick] = useState(DateTime.now())

  useEffect(() => {
    const tickInterval = setInterval(() => setTick(DateTime.now()), 5000)
    return () => clearInterval(tickInterval)
  })

  return <>
    <Box display="flex" alignItems="center">
      <Box>
        <Box>
          <Text variant="h4">Product Catalog</Text>
        </Box>
      </Box>

      <Box m={1} flexGrow={1} sx={{ textAlign: "right" }}>
        <Box sx={{ width: '100%' }} display={{ xs: 'none', md: 'block' }}>
          { loading ? <LinearProgress color="primary" /> : <Text key={tick.toISO()} component="span" color="slateGray" fontSize="small">Updated {(loadedAt || DateTime.now()).toRelative()}</Text>}
        </Box>
      </Box>

      <Box>
        <ToggleButtonGroup
          sx={{ marginLeft: 1 }}
          value={filters.display}
          exclusive
          onChange={setDisplay}
        >
          <ToggleButton value="table" size="small">
            <TableIcon />
          </ToggleButton>
          <ToggleButton value="grid" size="small">
            <GalleryIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>

    <Box mb={2}>
      <Box sx={{ width: '100%' }} display={{ xs: 'block', md: 'none' }}>
        { loading ? <LinearProgress color="primary" /> : <Text key={tick.toISO()} component="span" color="slateGray" fontSize="small">Updated {(loadedAt || DateTime.now()).toRelative()}</Text>}
      </Box>

      { filters.productType.map((value) => {
        const type = availableProductTypes.find((type) => type.value === value)
        if (!type) return null

        return <Chip size="small" key={type.value} label={type.label} sx={{ mr: 1 }} />
      })}


      { filters.productTemperatureTagIds.map((id) => {
        const tag = availableTemperatureTags.find((tag) => tag.value === id)
        if (!tag) return null

        return <Chip size="small" key={tag.value} label={tag.label} sx={{ mr: 1 }} />
      })}

      { filters.productDietaryTagIds.map((id) => {
        const tag = availableDietaryTags.find((tag) => tag.value === id)
        if (!tag) return null

        return <Chip size="small" key={tag.value} label={tag.label} sx={{ mr: 1 }} />
      })}

      { filters.productLocationIds.map((id) => {
        const location = availableProductLocations.find((location) => location.value === id)
        if (!location) return null

        return <Chip size="small" key={location.value} label={location.label} sx={{ mr: 1 }} />
      })}

      { filters.productName && filters.productName !== '' && <Chip size="small" label={`"${filters.productName}"`} sx={{ mr: 1 }} /> }
    </Box>
  </>
}

export default FiltersHeader
