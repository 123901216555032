import React from 'react'
import Markdown from 'react-markdown'
import { FormattedNumber } from 'react-intl'
import { Box, Text } from 'components'
import { ReportTable } from './ReportTable'
import { ReportGrid } from './ReportGrid'
import { VendorFulfillmentReport } from '@kitchen/graphql/schema/graphql'
import { styled } from '@mui/system'

const DeliveryMarkdown = styled(Markdown)({
  whiteSpace: 'normal',
  'p': { padding: 0, margin: 0 }
})

export const FulfillmentReport: React.FC<{ reports: VendorFulfillmentReport[], display?: 'table' | 'grid' | null }> = ({ reports, display='grid' }) => {

  return <>
    {reports.map((report) => {
      return <Box key={report.location.id} mb={3} sx={{ flexGrow: 0 }}>
        <Box mb={4} sx={(theme) => ({ borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: theme.palette.divider})}>
          <Box>
            <Text variant="h5" display="inline" fontWeight="medium">{report.location.displayName}</Text>
            <Text variant="h5" display="inline">&nbsp;&bull;&nbsp;</Text>
            <Text variant="h5" display="inline"><FormattedNumber value={report.total} /> items</Text>
          </Box>

          { report.location.address && <Box>
            <Box>
              <Text>{ report.location.address.displayName }</Text>
            </Box>

            { report.location.address.deliveryInstructions &&
              <Box whiteSpace="pre-line" pl={1} ml={1} my={1} sx={(theme) => ({ borderLeftStyle: 'solid', borderLeftWidth: '2px', borderLeftColor: theme.palette.divider })}>
                <DeliveryMarkdown>{ report.location.address.deliveryInstructions.replace(/(\r\n|\r)+/g, "\n") }</DeliveryMarkdown>
              </Box> }
          </Box> }
        </Box>

        { display === 'table' && <ReportTable {...report} /> }
        { display === 'grid' && <ReportGrid {...report} /> }
      </Box>
    })}
  </>
}

export default FulfillmentReport
