import { gql } from '@kitchen/graphql/schema/gql'

export const uploadKitchenDocumentMutation = gql(`
  mutation uploadKitchenDocumentMutation($input: UploadKitchenDocumentInput!) {
    uploadKitchenDocument(input: $input) {
      document {
        ...documentFragment
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default uploadKitchenDocumentMutation
