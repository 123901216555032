import { OperationVariables, QueryHookOptions, QueryResult, DocumentNode, TypedDocumentNode } from '@apollo/client'
import { useQuery } from 'hooks'
import useCurrentVendorLocation from './useCurrentVendorLocation'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useKitchenQuery = <TData = any, TVariables = OperationVariables>(query: DocumentNode | TypedDocumentNode<TData, TVariables>, options?: QueryHookOptions<TData, TVariables>): QueryResult<TData, TVariables> => {
  const { vendorLocation } = useCurrentVendorLocation()

  return useQuery<TData, TVariables>(query, {
    ...options,
    fetchPolicy: 'cache-and-network',
    context: {
      ...options.context,
      clientName: 'kitchen',
      headers: {
        'ORDO-TENANT-ORGANIZATION-ID': vendorLocation?.organization?.id,
        'ORDO-TENANT-LOCATION-ID': vendorLocation?.id
      }
    }
  })
}

export default useKitchenQuery