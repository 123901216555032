import { gql } from '@kitchen/graphql/schema/gql'

export const vendorFulfillmentReportsQuery = gql(`
  query vendorFulfillmentReportsQuery($vendorLocationId: ID!, $schoolLocationId: ID, $startDate: ISO8601Date, $endDate: ISO8601Date, $mode: String) {
    vendorFulfillmentReports(vendorLocationId: $vendorLocationId, schoolLocationId: $schoolLocationId, startDate: $startDate, endDate: $endDate, mode: $mode) {
      location {
        id
        displayName
        address {
          id
          displayName
          notes
          deliveryInstructions
        }
      }
      dates
      total
      rows {
        variant {
          id
          displayName
          product {
            ...VendorProductFields
          }
          location {
            id
            displayName
          }
        }
        total
        dates
      }
    }
  }
`)

export default vendorFulfillmentReportsQuery
