import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Page, Text } from 'components'
import { Documents as DocumentsPage, UploadDocumentForm, UploadDocumentDialog } from 'compositions'
import { OnSuccessFn } from 'hooks/useMutationForm'
import {
  useFilteredKitchenDocuments,
  useKitchenDocuments,
  useUploadKitchenDocumentForm,
} from '@kitchen/hooks'
import { UploadKitchenDocumentMutationData } from '@kitchen/hooks/useUploadKitchenDocument'
import { UploadKitchenDocumentInput } from '@kitchen/graphql/schema/graphql'

export const Documents: React.FC = () => {
  const [ uploadDocumentDialogOpen, setUploadDocumentDialogOpen ] = useState(false)
  const { data: { filteredKitchenDocuments, kitchenDocuments: { documentTypeOptions } }, refetch } = useFilteredKitchenDocuments()
  const onSuccess: OnSuccessFn<UploadKitchenDocumentMutationData, UploadKitchenDocumentInput> = (data, helpers) => {
    refetch()
    helpers.resetForm()
  }
  const form = useUploadKitchenDocumentForm({ onSuccess })

  return <Page fullWidth autoHeight>
    <DocumentsPage documents={filteredKitchenDocuments} documentTypeOptions={documentTypeOptions} onClickAddDocument={() => setUploadDocumentDialogOpen(true)}>
      <UploadDocumentDialog open={uploadDocumentDialogOpen} onClose={() => setUploadDocumentDialogOpen(false)} form={form} documentTypeOptions={documentTypeOptions} />
    </DocumentsPage>
  </Page>
}

export const UploadDocument: React.FC = () => {
  const { data: { kitchenDocuments: { documentTypeOptions } } } = useKitchenDocuments({ pollInterval: 600000 })
  const navigateTo = useNavigate()
  const onSuccess: OnSuccessFn<UploadKitchenDocumentMutationData, UploadKitchenDocumentInput> = () => {
    navigateTo('/settings/documents')
  }
  const form = useUploadKitchenDocumentForm({ onSuccess })

  return <Page fullWidth>
    <Text variant="h4" mb={2}>Upload document</Text>
    <UploadDocumentForm form={form} documentTypeOptions={documentTypeOptions} />
  </Page>
}

export default Documents
