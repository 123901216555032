import React from 'react'
import { Box, Text, Card, CardContent, CardMedia, Chip, Stack, TagChip } from 'components'
import Markdown from 'react-markdown'
import { Product } from '@kitchen/graphql/schema/graphql'
import { FormattedNumber } from 'react-intl'

export interface ProductCardProps {
  product: Product
}

export const ProductCard: React.FC<ProductCardProps> = ({ product }) => <Card sx={{ display: 'flex', flexDirection: 'column' }}>
  <CardMedia sx={{ height: '4rem' }} loading="lazy" image={(product.images[0]?.uri || product.featuredImage)} />

  <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box display="flex" pb={1} justifyContent="space-between">
        <Box>
          <Text variant="h6" lineHeight={1.1}>{ product.name }</Text>
        </Box>

        { product.cost && <Box>
          <Text><FormattedNumber value={product.cost / 100} style="currency" currency={product.currencyCode} /></Text>
        </Box> }
      </Box>

      <Box pb={2}>
        <Stack direction="row" flexWrap="wrap">
          { product.tags.filter((tag) => tag.type === "temperature").map((tag) => <TagChip key={tag.id} tag={tag} />) }
          { product.tags.filter((tag) => tag.type === "meal_type").map((tag) => <TagChip key={tag.id} tag={tag} />) }
        </Stack>
      </Box>

      { product.description && <Box pb={2}>
        <Markdown>{ product.description }</Markdown>
      </Box> }

      <Box flexGrow={1} display="flex" alignItems="flex-end">
        <Box>
          <Box>
            <Stack direction="row" flexWrap="wrap">
              { product.tags.filter((tag) => tag.type === "dietary").map((tag) => <TagChip key={tag.id} tag={tag} />) }
              { product.menus.map((menu) => menu.location).filter((v, i, a) => a.indexOf(v) === i).sort((a,b) => a.name.localeCompare(b.name)).map((location) => <Chip key={location.id} label={location.name} title={location.name} color="info" sx={{ mr: 1, mt: 1 }} />) }
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  </CardContent>
</Card>

export default ProductCard
