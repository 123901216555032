import React from 'react'
import { TodoWrapper, TodoWrapperProps } from 'components/Todos'
import { ChangePasswordForm } from 'compositions/ManageProfile/'
import { useChangePasswordForm } from 'hooks'

export const ChangePasswordTodo: React.FC<TodoWrapperProps> = ({ todo, wizard }) => {
  const onSuccess = () => {
    wizard.resolve(todo, { navigateToNext: true })
  }
  const form = useChangePasswordForm({ onSuccess })

  return <TodoWrapper todo={todo} wizard={wizard}>
  { form && <ChangePasswordForm form={form} /> }
</TodoWrapper>
}

export default ChangePasswordTodo
