import { gql } from '@kitchen/graphql/schema/gql'

export const vendorProductsQuery = gql(`
  query vendorProductsQuery($organizationId: ID!, $locationId: ID, $page: Int, $pageSize: Int, $filters: QueryFiltersInput, $sorting: [QuerySortInput]) {
    vendorProducts(organizationId: $organizationId, locationId: $locationId, page: $page, pageSize: $pageSize, filters: $filters, sorting: $sorting) {
      rowCount
      pageInfo {
        hasNextPage
        truncated
      }
      aggregations {
        field
        buckets {
          label
          value
          count
          total
        }
      }
      rows {
        ...VendorProductFields
        menus {
          id
          name
          startDate
          endDate
          location {
            id
            name
            displayName
          }
        }
        variants {
          id
          location {
            id
          }
        }
      }
    }
  }
`)

export default vendorProductsQuery
