import React from 'react'
import { FormattedNumber } from 'react-intl'
import { DataGridPro as DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro'
import { VendorFulfillmentReport, VendorFulfillmentReportRow } from '@kitchen/graphql/schema/graphql'
import { Box, Chip, Stack, Text } from 'components'
import Markdown from 'react-markdown'

export const ReportTable: React.FC<VendorFulfillmentReport> = ({ rows }) => {
  const columns: GridColDef[] = [
    {
      field: 'total',
      headerName: 'Quantity',
      maxWidth: 100,
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params: GridValueGetterParams<VendorFulfillmentReportRow, VendorFulfillmentReportRow>) => params.row.total,
      renderCell: (params: GridRenderCellParams<VendorFulfillmentReportRow>) => {
        return <Text variant="h6"><FormattedNumber value={params.row.total} /></Text>
      },
    },
    {
      field: 'name',
      headerName: 'Menu Item',
      flex: 2,
      minWidth: 200,
      valueGetter: (params: GridValueGetterParams<VendorFulfillmentReportRow, VendorFulfillmentReportRow>) => params.row.variant.product.name,
      renderCell: (params: GridRenderCellParams<VendorFulfillmentReportRow>) => {
        return <Box py={1} display="flex" alignItems="center">
          <Box height="104px"><img src={(params.row.variant.product.images[0]?.uri || params.row.variant.product.featuredImage)} style={{ maxHeight: '104px', width: 'auto' }} /></Box>
          <Text pl={1}>{params.row.variant.product.name}</Text>
        </Box>
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 3,
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams<VendorFulfillmentReportRow, VendorFulfillmentReportRow>) => params.row.variant.product.description,
      renderCell: (params: GridRenderCellParams<VendorFulfillmentReportRow>) => {
        return <Box py={1} pl={1}>
          <Markdown>{params.row.variant.product.description}</Markdown>
        </Box>
      },
    },
    {
      field: 'tags',
      headerName: 'Dietary',
      flex: 2,
      minWidth: 100,
      valueGetter: (params: GridValueGetterParams<VendorFulfillmentReportRow, VendorFulfillmentReportRow>) => params.row.variant.product.tags.map((tag) => tag.name).join(' '),
      renderCell: (params: GridRenderCellParams<VendorFulfillmentReportRow>) => {
        return <Box py={1}>
          <Stack direction="row" flexWrap="wrap" rowGap={1} columnGap={1} justifyContent="flex-start">
            { params.row.variant.product.tags.map((tag) => <Chip key={tag.id} label={tag.name} />) }
          </Stack>
        </Box>
      },
    },
  ]

  return <DataGrid columns={columns} rows={rows} getRowId={(row) => row.variant.id} getRowHeight={() => 'auto'} />
}

export default ReportTable
