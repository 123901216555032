import React from 'react'
import { TodosBadge, TodosBadgeProps } from 'components/Todos/TodosBadge'
import { useKitchenTodos, useCurrentVendorLocation } from '@kitchen/hooks'

export type KitchenTodosBadgeProps = Omit<TodosBadgeProps, 'todos'>

export const KitchenTodosBadge: React.FC<KitchenTodosBadgeProps> = (props) => {
  const { vendorLocation } = useCurrentVendorLocation()
  const { data: { kitchenTodos } } = useKitchenTodos({ location: vendorLocation })

  return <TodosBadge {...props} todos={kitchenTodos.rows} />
}

export default KitchenTodosBadge
