import React, { PropsWithChildren, createContext, useCallback, useReducer } from 'react'
import { useLocalStorage } from 'hooks'
import { VendorLocation } from '@kitchen/graphql/schema/graphql'

export type KitchenContext = {
  currentVendorLocation?: VendorLocation
  setCurrentVendorLocation?: (location: VendorLocation) => void
}

const initialState: KitchenContext = {}

export const ApplicationContext = createContext<KitchenContext>(initialState)

export type ContextAction =
  | { type: 'SET_CURRENT_VENDOR', payload: VendorLocation }

const contextReducer = (state: KitchenContext, action: ContextAction) => {
  switch(action.type) {
    case 'SET_CURRENT_VENDOR':
      return {
        ...state,
        currentVendorLocation: action.payload,
      }
    default:
      return state
  }
}

export const ContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [stored, setStored] = useLocalStorage<KitchenContext>('kitchenContext', initialState)

  const localStorageReducer = useCallback(
    (state: KitchenContext, action: ContextAction) => {
      const newState = contextReducer(state, action)
      setStored(newState)
      return newState
    },
    [setStored]
  )
  const [state, dispatch] = useReducer(localStorageReducer, stored)

  const setCurrentVendorLocation = useCallback(
    (location: VendorLocation) => dispatch({ type: 'SET_CURRENT_VENDOR', payload: location}),
    [dispatch]
  )

  const contextValue: KitchenContext = {
    ...state,
    setCurrentVendorLocation,
  }

  return <ApplicationContext.Provider value={contextValue}>
    {children}
  </ApplicationContext.Provider>
}

export default ContextProvider