import React from "react"
import { LocationSelector as BaseLocationSelector, Badge } from 'components'
import { VendorLocation } from '@kitchen/graphql/schema/graphql'

export const LocationSelector: React.FC<{ vendorLocations: VendorLocation[], currentLocation: VendorLocation, setCurrentVendorLocation: (location: VendorLocation) => void }> = ({ vendorLocations, currentLocation, setCurrentVendorLocation }) => {
  const options = vendorLocations.map((location) => ({
    label: location.name === location.organization.name ? location.name : `${location.organization.name} ${location.name}`,
    id: location.id,
    todoCount: location.todoCount,
  })).sort((a, b) => a.label.localeCompare(b.label))
  const selected = options.find(({ id }) => id === currentLocation.id)
  const badgeCount = (options.map((opt) => opt.todoCount).reduce((sum, a) => sum + a, 0) || 0) - (selected?.todoCount || 0)

  return <Badge sx={{ width: '100%'}} badgeContent={badgeCount} color="error">
    <BaseLocationSelector label="Selected Kitchen" options={options} selected={selected} onChange={(value) => setCurrentVendorLocation(vendorLocations.find((location) => value.id === location.id))} />
  </Badge>
}

export default LocationSelector
