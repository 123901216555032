import { useEffect, useMemo, useState } from 'react'
import type { Product, VendorLocation, QueryFiltersInput, QueryAggregation, QueryAggregationBucket } from '@kitchen/graphql/schema/graphql'
import { useFilters, Filter, FilterProps as BaseFilterProps } from 'hooks/useFilters'
import { useVendorProducts } from './useVendorProducts'

export interface FilterState {
  display: "table" | "grid"
  productName: string
  productTemperatureTagIds: string[]
  productDietaryTagIds: string[]
  productLocationIds: string[]
  productType: string[]
}

export type FilterProps = Pick<BaseFilterProps<FilterState, Product>, "filters" | "appliedFilters" | "filterDefinitions" | "setFilters" | "applyFilters" | "setAndApplyFilters" | "clearFilters" | "applicable" | "clearable"> & Pick<ReturnType<typeof useVendorProducts>, "loading" | "loadedAt"> & {
  availableTemperatureTags: QueryAggregationBucket[]
  availableDietaryTags: QueryAggregationBucket[]
  availableProductLocations: QueryAggregationBucket[]
  availableProductTypes: QueryAggregationBucket[]
}

const displayFilter: Filter<FilterState, Product, 'display'> = {
  name: 'display',
}

const productNameFilter: Filter<FilterState, Product, 'productName'> = {
  name: 'productName',
  label: 'Product Name',
  match: (product, { productName }) => {
    return true
    if (!productName || productName === '') return true
    if (product.name.match(new RegExp(`${productName}`, 'i'))) return true
    return
  },
}

const productTemperatureTagFilter: Filter<FilterState, Product, 'productTemperatureTagIds'> = {
  name: 'productTemperatureTagIds',
  label: 'Temperature',
  match: (product, { productTemperatureTagIds }) => {
    return true
    const stateTagIds = productTemperatureTagIds
    const recordTagIds = product.tags.map((tag) => tag.id)
    let match = true

    if (stateTagIds.length > 0 && !stateTagIds.some(tagId => recordTagIds.includes(tagId))) {
      match = false
    }

    return match
  },
}

const productDietaryTagFilter: Filter<FilterState, Product, 'productDietaryTagIds'> = {
  name: 'productDietaryTagIds',
  label: 'Dietary',
  match: (product, { productDietaryTagIds }) => {
    return true
    const stateTagIds = productDietaryTagIds
    const recordTagIds = product.tags.map((tag) => tag.id)
    let match = true

    if (stateTagIds.length > 0 && !stateTagIds.every(tagId => recordTagIds.includes(tagId))) {
      match = false
    }

    return match
  },
}

const productLocationFilter: Filter<FilterState, Product, 'productLocationIds'> = {
  name: 'productLocationIds',
  label: 'Locations',
  match: (product, { productLocationIds }) => {
    return true
    const stateLocationIds = productLocationIds
    const recordLocationIds = product.menus.map((menu) => menu.location.id)
    let match = true

    stateLocationIds.forEach((menuId) => {
      if (recordLocationIds.indexOf(menuId) === -1) match = false
    })

    return match
  },
}

const productTypeFilter: Filter<FilterState, Product, 'productType'> = {
  name: 'productType',
  label: 'Product Type',
  match: (product, { productType }) => {
    return true
    const stateTypes = productType
    let match = true

    if (stateTypes.length > 0) {
      if (!stateTypes.includes(product.productType)) match = false
    }

    return match
  },
}

export const useFilteredVendorProducts = (vendorLocation: VendorLocation, initialState?: Partial<FilterState>) => {
  const filters = useFilters<FilterState, Product>(
    [
      displayFilter,
      productNameFilter,
      productTemperatureTagFilter,
      productDietaryTagFilter,
      productLocationFilter,
      productTypeFilter,
    ],
    {
      display: "table",
      productName: undefined,
      productTemperatureTagIds: [...(initialState?.productTemperatureTagIds || [])],
      productDietaryTagIds: [...(initialState?.productDietaryTagIds || [])],
      productLocationIds: [...(initialState?.productLocationIds || [])],
      productType: [],
    },
    [ 'display' ],
    "server"
  )

  const { filterData, setFilterOptions, appliedFilters, applyPagination, pagination, sorting } = filters

  const queryFilters: QueryFiltersInput = useMemo(() => ({
    filters: [
      {
        field: "tags",
        value: [ ...appliedFilters.productDietaryTagIds, ...appliedFilters.productTemperatureTagIds ],
      },
      {
        field: "locations",
        value: appliedFilters.productLocationIds,
      },
      {
        field: "productType",
        value: appliedFilters.productType,
      },
      {
        field: "productName",
        value: [appliedFilters.productName],
      },
    ],
  }), [ appliedFilters.productDietaryTagIds, appliedFilters.productTemperatureTagIds, appliedFilters.productLocationIds, appliedFilters.productType, appliedFilters.productName ])

  const [ aggregations, setAggregations ] = useState<QueryAggregation[]>([])

  const { data, ...rest } = useVendorProducts({
    organizationId: vendorLocation?.organization?.id,
    locationId: vendorLocation?.id,
    filters: queryFilters,
    sorting: sorting,
    page: pagination.page,
    pageSize: pagination.pageSize,
    pollInterval: 600000,
  })

  useEffect(() => {
    setAggregations([])
    applyPagination({ page: 0, pageSize: 25 })
  }, [ vendorLocation, applyPagination ])

  useEffect(() => {
    if (data && data.vendorProducts && data.vendorProducts.aggregations) {
      setAggregations(data.vendorProducts.aggregations)
    }
  }, [ setAggregations, data ])

  const availableTemperatureTags = useMemo(
    () => aggregations.find((agg) => agg.field === "temperature")?.buckets || [],
    [aggregations]
  )

  const availableDietaryTags = useMemo(
    () => aggregations.find((agg) => agg.field === "dietary")?.buckets || [],
    [aggregations]
  )

  const availableProductLocations = useMemo(
    () => aggregations.find((agg) => agg.field === "locations")?.buckets || [],
    [aggregations]
  )

  const availableProductTypes = useMemo(
    () => aggregations.find((agg) => agg.field === "productType")?.buckets || [],
    [aggregations]
  )

  useEffect(() => {
    setFilterOptions('productLocationIds', availableProductLocations.map((location) => ({ key: location.label, value: location.value, count: location.count })))
    setFilterOptions('productTemperatureTagIds', availableTemperatureTags.map((tag) => ({ key: tag.label, value: tag.value, count: tag.count })))
    setFilterOptions('productDietaryTagIds', availableDietaryTags.map((tag) => ({ key: tag.label, value: tag.value, count: tag.count })))
    setFilterOptions('productType', availableProductTypes.map((type) => ({ key: type.label, value: type.value, count: type.count })))
  }, [ setFilterOptions, availableProductLocations, availableTemperatureTags, availableDietaryTags, availableProductTypes ])

  const filteredProducts = useMemo(() => filterData(data?.vendorProducts?.rows || []), [filterData, data?.vendorProducts?.rows])

  return {
    data: {
      ...data,
      filteredProducts,
    },
    filters: {
      ...filters,
      pagination: {
        ...pagination,
        rowCount: data?.vendorProducts?.rowCount,
      },
      availableProductLocations,
      availableTemperatureTags,
      availableDietaryTags,
      availableProductTypes,
    },
    ...rest
  }
}

export default useFilteredVendorProducts
