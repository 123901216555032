import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Link, Text, Page } from 'components'
import { ResetPasswordForm } from 'compositions/ResetPasswordForm'
import OrdoLogo from 'theme/ordo-logo.svg'

export const ResetPassword: React.FC = () => {
  return <Page>
    <Box sx={{ width: { md: '600px' }, mx: { md: 'auto' } }}>
      <Box mb={2} textAlign="center">
        <Link component={RouterLink} to="/"><OrdoLogo height="60" /></Link>
      </Box>

      <Text variant="h3" textAlign="center" mb={4}>Reset password</Text>

      <ResetPasswordForm />
    </Box>
  </Page>
}

export default ResetPassword
