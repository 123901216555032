import { gql } from '@kitchen/graphql/schema/gql'

export const kitchenSettingsQuery = gql(`
  query kitchenSettingsQuery($locationId: ID) {
    kitchenSettings(locationId: $locationId) {
      ...KitchenSettingsFields
    }
  }
`)

export default kitchenSettingsQuery
