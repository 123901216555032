import type {} from '@mui/x-date-pickers-pro/themeAugmentation'
import { deepmerge } from '@mui/utils'
import { createTheme, ThemeOptions } from '@mui/material/styles'
// import { green } from '@mui/material/colors'
import { options as baseOptions } from 'theme'

export const options: ThemeOptions = {
  palette: {
    primary: {
      main: "#1FB998",
      contrastText: '#FFFFFF',
    }
  },
}

export const theme = createTheme(deepmerge(baseOptions, options))

export default theme
