import { useContext } from 'react'
import { ApplicationContext } from '@kitchen/compositions/Application'
import type { KitchenContext } from '@kitchen/compositions/Application'

export { KitchenContext }

export const useKitchenContext = (): KitchenContext => {
  const kitchenContext = useContext<KitchenContext>(ApplicationContext)
  return kitchenContext
}

export default useKitchenContext
