import React from 'react'
import { TodoWrapper, TodoWrapperProps } from 'components/Todos'
import { KitchenSettingsForm } from '@kitchen/compositions/KitchenSettings'
import { useCurrentVendorLocation } from '@kitchen/hooks'

export const KitchenSettingsTodo: React.FC<TodoWrapperProps> = ({ todo, wizard }) => {
  const { vendorLocation } = useCurrentVendorLocation()
  const onSuccess = () => {
    wizard.resolve(todo, { navigateToNext: true })
  }

  return <TodoWrapper todo={todo} wizard={wizard}>
  <KitchenSettingsForm kitchen={vendorLocation} onSuccess={onSuccess} />
</TodoWrapper>
}

export default KitchenSettingsTodo
