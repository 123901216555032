import { gql } from '@kitchen/graphql/schema/gql'

export const updateKitchenSettingsMutation = gql(`
  mutation updateKitchenSettingsMutation($input: KitchenSettingsInput! ) {
    updateKitchenSettings(input: $input) {
      settings {
        ...KitchenSettingsFields
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default updateKitchenSettingsMutation
