import { uploadKitchenDocumentMutation } from '@kitchen/graphql/mutations'
import { MutationTuple, useKitchenMutation } from './useKitchenMutation'
import type { UploadKitchenDocumentInput, UploadKitchenDocumentPayload } from '@kitchen/graphql/schema/graphql'
export type { UploadKitchenDocumentInput }

export type UploadKitchenDocumentMutationData = { uploadKitchenDocument: UploadKitchenDocumentPayload }

export const useUploadKitchenDocument: (variables?: UploadKitchenDocumentInput) => MutationTuple<UploadKitchenDocumentMutationData, UploadKitchenDocumentInput> = (variables)  => {
  const [ uploadDocumentFn, uploadDocumentResult ] = useKitchenMutation<UploadKitchenDocumentMutationData, UploadKitchenDocumentInput>(
    uploadKitchenDocumentMutation,
    {
      variables,
      // notifyOnNetworkStatusChange: true
    }
  )

  return [ uploadDocumentFn, uploadDocumentResult ]
}

export default useUploadKitchenDocument
