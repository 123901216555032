import React from 'react'
import { Page } from 'components'
import { KitchenSettings } from '@kitchen/compositions'

export const Settings: React.FC = () => {
  return <Page>
    <KitchenSettings />
  </Page>
}

export default Settings
