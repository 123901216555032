import React from 'react'
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel, GridValueGetterParams } from '@mui/x-data-grid'
import type { Product } from '@kitchen/graphql/schema/graphql'
import { Box, Chip, Stack, Text, TagChip } from 'components'
import Markdown from 'react-markdown'
import { truncate } from 'lodash'
import { FormattedNumber } from 'react-intl'
import { useViewport } from 'hooks'
import { FilterPagination, ApplyPagination, ApplySorting } from 'hooks/useFilters'

export const ProductsTable: React.FC<{
  products: Product[],
  pagination: FilterPagination,
  applyPagination: ApplyPagination,
  sorting: GridSortModel,
  applySorting: ApplySorting,
}> = ({
  products,
  pagination: { rowCount, ...paginationModel },
  applyPagination,
  sorting,
  applySorting,
}) => {
  const { isExtraLarge } = useViewport()

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Product',
      filterable: false,
      flex: 2,
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams<Product, Product>) => params.row.name,
      renderCell: (params: GridRenderCellParams<Product>) => {
        return <Box>
          <Box py={1} display="flex" alignItems="center">
            <Box height="104px"><img src={(params.row.images[0]?.uri || params.row.featuredImage)} style={{ maxHeight: '104px', width: 'auto' }} /></Box>
            <Box>
              <Text pl={1}>{params.row.name}</Text>
              { isExtraLarge && <Box py={1} pl={1}>
                <Stack direction="row" flexWrap="wrap" rowGap={1} columnGap={1} justifyContent="flex-start">
                  { params.row.tags.filter((tag) => tag.type === "temperature").map((tag) => <TagChip key={tag.id} tag={tag} />) }
                  { params.row.tags.filter((tag) => tag.type === "meal_type").map((tag) => <TagChip key={tag.id} tag={tag} />) }
                </Stack>
              </Box> }
            </Box>
          </Box>
          { !isExtraLarge && <Box py={1}>
              <Stack direction="row" flexWrap="wrap" rowGap={1} columnGap={1} justifyContent="flex-start">
                { params.row.tags.filter((tag) => tag.type === "temperature").map((tag) => <TagChip key={tag.id} tag={tag} />) }
                { params.row.tags.filter((tag) => tag.type === "meal_type").map((tag) => <TagChip key={tag.id} tag={tag} />) }
              </Stack>
            </Box> }
        </Box>
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      filterable: false,
      sortable: false,
      flex: 3,
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams<Product, Product>) => params.row.description,
      renderCell: (params: GridRenderCellParams<Product>) => {
        return <Box py={1}>
          <Box pl={1}><Markdown>{params.row.description}</Markdown></Box>
          { !isExtraLarge && <Box py={1}>
            <Stack direction="row" flexWrap="wrap" rowGap={1} columnGap={1} justifyContent="flex-start">
              { params.row.tags.filter((tag) => tag.type === "dietary").map((tag) => <TagChip key={tag.id} tag={tag} />) }
              { params.row.menus.map((menu) => menu.location).filter((v, i, a) => a.indexOf(v) === i).sort((a,b) => a.name.localeCompare(b.name)).map((location) => <Chip key={location.id} label={truncate(location.name, { length: 20 })} title={location.name} color="info" />) }
            </Stack>
          </Box> }
        </Box>
      },
    },
    {
      field: 'cost',
      headerName: 'Cost',
      filterable: false,
      flex: 1,
      valueGetter: (params: GridValueGetterParams<Product, Product>) => params.row.cost,
      renderCell: (params: GridRenderCellParams<Product>) => {
        if (params.row.cost) {
          return <Text><FormattedNumber value={params.row.cost / 100} style="currency" currency={params.row.currencyCode} /></Text>
        } else {
          return null
        }
      },
    },
    ( isExtraLarge && {
      field: 'tags',
      headerName: 'Dietary',
      filterable: false,
      sortable: false,
      flex: 2,
      minWidth: 100,
      valueGetter: (params: GridValueGetterParams<Product, Product>) => params.row.tags.map((tag) => tag.name).join(' '),
      renderCell: (params: GridRenderCellParams<Product>) => {
        return <Box py={1}>
          <Stack direction="row" flexWrap="wrap" rowGap={1} columnGap={1} justifyContent="flex-start">
            { params.row.tags.filter((tag) => tag.type === "dietary").map((tag) => <TagChip key={tag.id} tag={tag} />) }
          </Stack>
        </Box>
      },
    }),
    ( isExtraLarge && {
      field: 'locations',
      headerName: 'Locations',
      filterable: false,
      sortable: false,
      flex: 3,
      // minWidth: 200,
      valueGetter: (params: GridValueGetterParams<Product, Product>) => params.row.menus.map((menu) => menu.location.name).filter((v, i, a) => a.indexOf(v) === i).sort((a,b) => a.localeCompare(b)).join(' '),
      renderCell: (params: GridRenderCellParams<Product>) => {
        return <Box py={1}>
          <Stack direction="row" flexWrap="wrap" rowGap={1} columnGap={1} justifyContent="flex-start">
            { params.row.menus.map((menu) => menu.location).filter((v, i, a) => a.indexOf(v) === i).sort((a,b) => a.name.localeCompare(b.name)).map((location) => <Chip key={location.id} label={truncate(location.name)} title={location.name} color="info" />) }
          </Stack>
        </Box>
      },
    }),
  ].filter(Boolean)

  return <DataGrid
    columns={columns}
    rows={products}
    disableColumnFilter
    rowCount={rowCount}
    paginationMode="server"
    paginationModel={paginationModel}
    onPaginationModelChange={applyPagination}
    sortingMode="server"
    sortModel={sorting}
    onSortModelChange={applySorting}
    getRowHeight={() => 'auto'}
  />
}

export default ProductsTable
