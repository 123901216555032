import { useMemo } from 'react'
import { kitchenSettingsQuery } from '@kitchen/graphql/queries'
import { useKitchenQuery } from './useKitchenQuery'
import type { KitchenSettings } from '@kitchen/graphql/schema/graphql'
import { DateTime } from 'luxon'

type KitchenSettingsQueryArgs = {
  locationId: string
}

type KitchenSettingsArgs = KitchenSettingsQueryArgs & {
  pollInterval?: number
}

export const useKitchenSettings = ({ locationId, pollInterval }: KitchenSettingsArgs) => {
  const { data, ...rest } = useKitchenQuery<{ kitchenSettings: KitchenSettings }, KitchenSettingsQueryArgs>(
    kitchenSettingsQuery,
    {
      variables: { locationId },
      pollInterval,
      // notifyOnNetworkStatusChange: true
    }
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadedAt = useMemo(() => DateTime.now(), [data]) // we only want to regenerate this when the data is fetched/changed

  return {
    ...rest,
    loadedAt,
    data: {
      kitchenSettings: [],
      ...data,
    }
  }
}

export default useKitchenSettings
