import { enqueueSnackbar } from 'notistack'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { VendorLocation, KitchenSettingsInput } from '@kitchen/graphql/schema/graphql'
import { useMutationForm, MutationFormTuple, CoerceFn, MutationFormConfig, OnSuccessFn } from 'hooks/useMutationForm'
import { UpdateKitchenSettingsMutationData, useUpdateKitchenSettings } from './useUpdateKitchenSettings'

export const zodSchema = z.object({
  vendorName: z.optional(z.string()),
  kitchenName: z.string({
    required_error: 'Kitchen name is required',
  }).nonempty(),
  address: z.object({
    id: z.string(),
    line1: z.string({
      required_error: 'Street address is required',
    }).nonempty(),
    city: z.string({
      required_error: 'City is required',
    }).nonempty(),
    stateProvince: z.string({
      required_error: 'State is required',
    }).nonempty(),
    postalCode: z.string({
      required_error: 'Postal code is required',
    }).nonempty(),
    phoneNumber: z.string().min(10, "must be a valid phone number").max(10, "must be a valid phone number"),
    phoneExtension: z.optional(z.string()),
    contactFirstName: z.string().nonempty(),
    contactLastName: z.string().nonempty(),
  }),
})

export type KitchenSettingsFormInput = z.infer<typeof zodSchema> & { id: string }

export const validationSchema = toFormikValidationSchema(zodSchema)

export const useUpdateKitchenSettingsForm = (kitchen: VendorLocation, config?: MutationFormConfig<UpdateKitchenSettingsMutationData, KitchenSettingsFormInput>): MutationFormTuple<UpdateKitchenSettingsMutationData, KitchenSettingsInput> => {
  const coerce: CoerceFn<KitchenSettingsInput, KitchenSettingsFormInput> = (values) => {
    const coerced: KitchenSettingsInput = {
      name: values.kitchenName,
      address: values.address,
    }

    return coerced
  }

  const onSuccess: OnSuccessFn<UpdateKitchenSettingsMutationData, KitchenSettingsFormInput> = (data, helpers) => {
    enqueueSnackbar("Settings saved", { variant: "success", autoHideDuration: 2000, preventDuplicate: true })
    if (config.onSuccess) config.onSuccess(data, helpers)
  }

  const [ mutationForm, mutationResult] = useMutationForm<UpdateKitchenSettingsMutationData, KitchenSettingsInput, KitchenSettingsFormInput>(useUpdateKitchenSettings, {
    ...config,
    coerce,
    onSuccess,
    validationSchema,
    initialValues: {
      id: kitchen.id,
      vendorName: kitchen.settings?.vendorName,
      kitchenName: kitchen.settings?.name,
      address: {
        id: kitchen.settings?.address?.id,
        line1: kitchen.settings?.address?.line1 || '',
        city: kitchen.settings?.address?.city || '',
        stateProvince: kitchen.settings?.address?.stateProvince || '',
        postalCode: kitchen.settings?.address?.postalCode || '',
        phoneNumber: kitchen.settings?.address?.phoneNumber || '',
        phoneExtension: kitchen.settings?.address?.phoneExtension || '',
        contactFirstName: kitchen.settings?.address?.contactFirstName || '',
        contactLastName: kitchen.settings?.address?.contactLastName || '',
      },
    }
  })

  return [ mutationForm, mutationResult ]
}

export default useUpdateKitchenSettingsForm