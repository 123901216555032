import React from 'react'
import { Page } from 'components'
import { Router } from 'components/Todos'
import { useCurrentVendorLocation, useKitchenTodos } from '@kitchen/hooks'

export const Todos: React.FC = () => {
  const { vendorLocation } = useCurrentVendorLocation()
  const { data: { kitchenTodos }, refetch } = useKitchenTodos({ location: vendorLocation })

  return <Page fullWidth>
    <Router todos={kitchenTodos.rows} refetch={refetch} />
  </Page>
}

export default Todos
