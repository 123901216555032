import { gql } from '@kitchen/graphql/schema/gql'

export const submitVendorSupportRequestMutation = gql(`
  mutation submitVendorSupportRequestMutation($input: SubmitVendorSupportRequestInput!) {
    submitVendorSupportRequest(input: $input) {
      requestId
      errors {
        code
        message
      }
    }
  }
`)

export default submitVendorSupportRequestMutation
