import { gql } from '@kitchen/graphql/schema/gql'

export const kitchenTodosQuery = gql(`
  query kitchenTodosQuery($locationId: ID!) {
    kitchenTodos(locationId: $locationId) {
      rowCount
      pageInfo {
        hasNextPage
        truncated
      }
      rows {
        ...todoFragment
      }
    }
  }
`)

export default kitchenTodosQuery
